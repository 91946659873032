import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import ThemeContext from "../context/ThemeContext"
import favicon from "../../content/images/logo.png"

const links = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "Contact",
    link: "/contact",
  },
]

const Header = () => {
  const theme = useContext(ThemeContext) // eslint-disable-line
  // determined if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = useState(false)

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component un-mounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  return (
    <header>
      <div className="headerContent">
        <div className={scrolled ? "hidden" : "themeButton"}>
          <input
            type="checkbox"
            className="uiswitch"
            onClick={theme.toggleDark}
            checked={!theme.dark}
            aria-label="Toggle Theme"
            title="Toggle Theme"
            readOnly
          />
        </div>
        <div className="logo">
          <Link to="/">
            <img src={favicon} alt="Krishna Prasanth Vemuganti" />
            <h3 className="bigName">Krishna Prasanth Vemuganti</h3>
            <h3 className="smallName">Krishna</h3>
          </Link>
        </div>
        <div className="links">
          {links.map((link) => (
            <Link
              className="link"
              activeClassName="active"
              key={link.name}
              to={link.link}
            >
              <span>{link.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </header>
  )
}

export default Header
