import React from "react"
import { Link } from "gatsby"
import { FormatDate, NonStretchedImage } from "./shared"

const CheckSpecial = (props) => {
  if (props.special)
    return (
      <div>
        {props.string}
        <span className="special">&nbsp;{props.special}&nbsp;</span>
      </div>
    )
  return ""
}

const BlogList = (props) => {
  const title = props.title
  const special = props.special
  const posts = props.posts
  const count = props.count
  return (
    <div className="blogList">
      {title && (
        <div className="dividerTitle">
          {title}
          <CheckSpecial special={special} string=":" />
          <CheckSpecial special={count} string="-" />
        </div>
      )}
      {posts
        .filter((post) => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => {
          const date = FormatDate(post.frontmatter.date)
          return (
            <div key={post.frontmatter.date}>
              <Link to={post.frontmatter.path}>
                <div className="blogItem">
                  <div className="blogItemContent">
                    {post.frontmatter.thumbnail ? (
                      <section>
                        <div className="row">
                          <div className="column eighteen left">
                            <div className="blogThumbImg">
                              <NonStretchedImage
                                fluid={
                                  post.frontmatter.thumbnail.childImageSharp
                                    .fluid
                                }
                              />
                            </div>
                          </div>
                          <div className="column eightyTwo leftNoChange">
                            <h1>{post.frontmatter.title}</h1>
                            <div className="blogDateTime">
                              {date} · {post.fields.readingTime.text}
                            </div>
                            <div className="blogPartialDesc">
                              {post.excerpt}
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <div>
                        {post.frontmatter.featuredImage && (
                          <div className="blogImg">
                            <NonStretchedImage
                              fluid={
                                post.frontmatter.featuredImage.childImageSharp
                                  .fluid
                              }
                            />
                          </div>
                        )}
                        <section>
                          <h1>{post.frontmatter.title}</h1>
                          <div className="blogDateTime">
                            {date} · {post.fields.readingTime.text}
                          </div>
                          <div className="blogPartialDesc">{post.excerpt}</div>
                        </section>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
    </div>
  )
}

export default BlogList
