import React from "react"

export default function Comments({ commentBox }) {
  return (
    <div id="comments" className="marginTopForty">
      <h2>Comments</h2>
      <hr />
      <div ref={commentBox} />
    </div>
  )
}
