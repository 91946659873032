import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import moment from "moment"
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaArrowCircleUp,
} from "react-icons/fa"

import config from "../config"

const _ = require("lodash")

const FormatDate = (date) => moment(new Date(date)).format(config.dateFormat)

const Capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

/* eslint-disable react/jsx-props-no-spreading */
const NonStretchedImage = (props) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || { margin: "0 auto" }), // Used to center the image
        maxWidth: props.fluid.presentationWidth,
      },
    }
  }
  return <Img {...normalizedProps} />
}

const BackButton = React.forwardRef(({ children, ...props }, ref) => {
  const onClick = (e) => {
    e.preventDefault()
    window.history.back()
  }
  return (
    <Link to="/" {...props} ref={ref} onClick={onClick}>
      {children}
    </Link>
  )
})

const InternalLink = (props) => (
  <span className="links">
    <Link to={props.Link}>{props.Name}</Link>
  </span>
)

const ExternalLink = (props) => (
  <span className="links">
    <a href={props.Link} target="_blank" rel="noopener noreferrer">
      {props.Name}
    </a>
  </span>
)

const Quote = (props) => (
  <div className="quotes">
    <div className="quote">{props.quote}</div>
    <cite>{props.cite}</cite>
  </div>
)

const HRule = () => <hr className="marginFifty" />

const Social = (props) => {
  const shareURL = encodeURIComponent(`${config.siteUrl}${props.share}`)
  const facebookURL = `https://www.facebook.com/share.php?u=${shareURL}`
  const twitterURL = `https://twitter.com/share?text=${props.title}&url=${shareURL}&via=${config.userTwitter}`
  const linkedinURL = `https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}`

  return (
    <span>
      <a href={facebookURL} target="_blank" rel="noopener noreferrer">
        <FaFacebookSquare
          className="icon shareColor facebook marginRightFive"
          alt="share on facebook"
          title="share on facebook"
        />
      </a>
      <a href={twitterURL} target="_blank" rel="noopener noreferrer">
        <FaTwitterSquare
          className="icon shareColor twitter marginRightFive"
          alt="share on twitter"
          title="share on twitter"
        />
      </a>
      <a href={linkedinURL} target="_blank" rel="noopener noreferrer">
        <FaLinkedin
          className="icon shareColor linkedin noMarginRight"
          alt="share on linkedin"
          title="share on linkedin"
        />
      </a>
    </span>
  )
}

const TagsList = (props) => {
  const { tags, type } = props

  return (
    <div className="tags">
      {tags &&
        tags.map((tag) => (
          <Link
            key={tag}
            style={{ textDecoration: "none" }}
            to={`/${type}/${_.kebabCase(tag)}/`}
          >
            <span>{tag}</span>
          </Link>
        ))}
    </div>
  )
}

const Modal = (props) => {
  const onClose = () => {
    if (props.onClose) props.onClose()
  }

  return props.show ? (
    <div className="modal">
      <div className="content">
        <div className="actions">
          <button onClick={onClose}>X</button>
        </div>
        <div className="data">{props.children}</div>
      </div>
    </div>
  ) : null
}

/* modal usage
const [show, setShow] = useState(false)
const showModal = () => {
  setShow(!show)
}

const modalDiv = (
  <Modal onClose={showModal} show={show}>
    <object data={resume} width="100%" height="800"></object>
  </Modal>
)
<Container modal={modalDiv}>
*/

const ScrollToTop = ({ onScrollOffset }) => {
  const [show, setShow] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > onScrollOffset) {
      setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (onScrollOffset) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <div>
      {show && (
        <div id="scrollToTopBtn" className="scrollToTopBtn">
          <FaArrowCircleUp
            className="icon"
            alt="arrowUp"
            onClick={handleClick}
          />
        </div>
      )}
    </div>
  )
}

export {
  NonStretchedImage,
  BackButton,
  FormatDate,
  InternalLink,
  ExternalLink,
  Capitalize,
  Quote,
  HRule,
  Social,
  TagsList,
  Modal,
  ScrollToTop,
}
