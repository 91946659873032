const config = {
  siteTitle: "Krishna Prasanth Vemuganti",
  siteTitleShort: "Krishna",
  siteLogo: "/logo.png",
  siteUrl: "https://www.krishnaprasanth.com",
  repo: "https://github.com/KrishnaPrasanthVemuganti",
  pathPrefix: "/",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription: "Krishna Prasanth Vemuganti, a full stack web developer.",
  googleAnalyticsID: "UA-160704958-1",
  userName: "Krishna",
  userEmail: "me@krishnaprasanth.com",
  userTwitter: "KrishnaDotCom",
  themeColor: "#2a3b4d",
  backgroundColor: "#FFFFFF",
}

module.exports = config
