import React from "react"
import { Helmet } from "react-helmet"

import config from "../config"

const SEO = (props) => {
  const replacePath = (path) => (path === `/` ? path : path.replace(/\/$/, ``))
  const { postNode, postPath, postSEO } = props
  let title
  let description
  let keywords = []
  let image = ""
  let postURL

  if (postSEO) {
    const postMeta = postNode.frontmatter
    title = postMeta.title
    description = postMeta.description ? postMeta.description : postNode.excerpt
    keywords = postMeta.keywords ? postMeta.keywords : []
    if (postMeta.featuredImage) {
      image = postMeta.featuredImage.childImageSharp.fixed.src
    }
    postURL = `${config.siteUrl}${replacePath(postPath)}`
  } else {
    title = config.siteTitle
    description = config.siteDescription
    image = config.siteLogo
  }

  image = `${config.siteUrl}${image}`
  const blogURL = `${config.siteUrl}${config.pathPrefix}`
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    },
  ]

  if (postSEO) {
    schemaOrgJSONLD.push(
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": postURL,
              name: title,
              image,
            },
          },
        ],
      },
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        url: blogURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
        headline: title,
        image: {
          "@type": "ImageObject",
          url: image,
        },
        description,
      }
    )
  }
  return (
    <Helmet defer={false}>
      <html lang="en" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(",")} />
      <meta name="image" content={image} />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <meta property="og:url" content={postSEO ? postURL : blogURL} />
      {postSEO && <meta property="og:type" content="article" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.userTwitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default SEO
