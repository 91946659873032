import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import ThemeContext from "../context/ThemeContext"
import Header from "./header"
import Footer from "./footer"
import config from "../config"
import favicon from "../../content/images/logo.png"
import { ScrollToTop } from "./shared"

//  Importing the main stylesheet
import "../styles/site.scss"

const Container = (props) => {
  const context = useContext(ThemeContext) // eslint-disable-line
  const { dark, notFound } = context
  let themeClass = ""

  if (dark && !notFound) {
    themeClass = "dark"
  } else if (notFound) {
    themeClass = "not-found"
  }

  let containerClass = "container"
  if (!props.index) containerClass += " footerPadding"
  return (
    <div className={containerClass}>
      <a className="skip-link" href="#main">
        Skip to main
      </a>
      <Helmet
        bodyAttributes={{
          class: `theme ${themeClass}`,
        }}
        title={`${props.title} - ${config.siteTitle}`}
        defer={false}
      >
        <meta name="description" content={config.siteDescription} />
        <link rel="shortcut icon" type="image/png" href={favicon} />
      </Helmet>
      {props.index ? (
        <div>{props.children}</div>
      ) : (
        <div>
          {props.modal}
          <Header />
          <main id="main">
            <div className="content">{props.children}</div>
          </main>
          <Footer />
        </div>
      )}
      <ScrollToTop onScrollOffset={300} />
    </div>
  )
}

export default Container
