import React from "react"
import {
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaGithub,
  FaHeart,
  FaCopyright,
} from "react-icons/fa"

import { ExternalLink, HRule } from "./shared"

const Footer = () => {
  return (
    <footer>
      <div className="footerContent row">
        <HRule />
        <div className="column full msgBottom">
          Built with <FaHeart className="icon heart" /> using{" "}
          <ExternalLink Link="https://www.gatsbyjs.org/" Name="Gatsby" />
          {" and "}
          Hosted on{" "}
          <ExternalLink Link="https://www.netlify.com/" Name="Netlify" />
        </div>
        <div className="column fifty left">
          <FaCopyright className="icon copy noMarginRight" alt="copyright" />{" "}
          Krishna Prasanth
        </div>
        <div className="column fifty right">
          <a
            href="https://www.instagram.com/krishnaprasanthvemuganti/"
            target="_blank"
            title="me@instagram"
            rel="noopener noreferrer"
          >
            <FaInstagram className="icon" alt="Me@instagram" />
          </a>
          <a
            href="https://twitter.com/KrishnaDotCom"
            target="_blank"
            title="me@twitter"
            rel="noopener noreferrer"
          >
            <FaTwitter className="icon" alt="me@twitter" />
          </a>
          <a
            href="https://www.linkedin.com/in/krishnaprasanth/"
            target="_blank"
            title="me@linkedin"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="icon" alt="me@linkedin" />
          </a>
          <a
            href="https://github.com/KrishnaPrasanthVemuganti"
            target="_blank"
            title="me@github"
            rel="noopener noreferrer"
          >
            <FaGithub className="icon noMarginRight" alt="me@github" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
